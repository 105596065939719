.modal-backdrop, .modal-backdrop.show{
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(0, 57, 77, 0.7);
    opacity: 1;
}
.dgpopup .modal-dialog{
    /*display: inline-block;*/
    width:100%;
    margin:auto;
    text-align: left;
    vertical-align: middle;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.dgpopup .modal-body{
    padding:0;
}
.dgpopup .modal-dialog{
    max-width: none;
}
.dgpopup .modal-content{
    background: none;
    border: none;
    margin-left: 8px;
}
#addendorsepop.dgpopup{
    padding: 0!important;
}

.addendorsepop{
    max-width: 460px;
    margin: 0 auto;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 3px 26px #00394D1A;
    border-radius: 8px;
    padding:15px 30px;
}
.addendorsepop h4{
    font-family: 'Ubuntu', sans-serif;
    /*font-weight: 700;
    font-size: 18px;
    line-height: 22px;*/
    color: #00394D;
    text-align:center;
    text-transform:uppercase;
    margin:0 0 20px;
}
.addendorsepop label, .profeditrow .profeditmain label{
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #43454A;
    letter-spacing: .028em;
    padding:0;
    max-width: none;
    margin: 0 0 6px;
}
.addendorsepop .custom-input, .profeditmain .custom-input{
    margin: 0;
}
.addendorsepop .form-control, .profeditmain .form-control{
    box-shadow: none;
    border-color:#DDDFE2;
}
.addendorsepop textarea.form-control, .profeditmain textarea.form-control{
    width: 100%;
    height: 100px;
    resize: none;
}
.addendorsepop .form-control:focus, .profeditmain .form-control:focus{
    border-color: #BFC52E;
    box-shadow: 0px 3px 6px #4F7D561A;
}
.addendorsepop .formbottom{
    padding-top:15px;
    text-align:center;
}
.addendorsepop .btn-primary, .profeditmain .btn-primary{
    padding:10px 20px;
    background:#00394D;
    border-radius: 4px;
    border:none;
    font-family: 'Ubuntu', sans-serif;
    font-weight:600;
    font-size: 12px;
    line-height: 15px;
    color:#ffffff;
    text-transform:uppercase;
}
.addendorsepop .btn-primary:hover, .addendorsepop .btn-primary:active, .addendorsepop .btn-primary:focus,
.profeditmain .btn-primary:hover, .profeditmain .btn-primary:active, .profeditmain .btn-primary:focus{
    background:#154A59 !important;
    color:#ffffff !important;
    box-shadow:none !important;
}
.addendorsepop .btn-cancel, .profeditmain .btn-cancel, .bottmbtns .btn-cancel{
    padding:10px 20px;
    background:#E7F3E3;
    border:none;
    border-radius: 4px;
    font-family: 'Ubuntu', sans-serif;
    font-weight:600;
    font-size: 12px;
    line-height: 15px;
    color:#698F8A;
    text-transform:uppercase;
    margin-left: 3px;
}
.addendorsepop .btn-cancel:hover, .addendorsepop .btn-cancel:active, .addendorsepop .btn-cancel:focus,
.profeditmain .btn-cancel:hover, .profeditmain .btn-cancel:active, .profeditmain .btn-cancel:focus,
.bottmbtns .bottmbtns .btn-cancel, .bottmbtns .btn-cancel:active, .bottmbtns .btn-cancel:focus{
    box-shadow:none !important;
    background:#CFE7C7 !important;
    color:#698F8A !important;
}
.dgpopup .modal-dialog{
    max-width: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto!important;
}
.dgpopup .modal-content{
    background: none;
    border: none;
}