.ql-toolbar.ql-snow {
    padding: 1px
}

.ql-editor strong{
    font-weight:bold;
}

.ql-editor em{
    font-style: italic;
}
