.react-html5-camera-photo > video {
    width: 100%;
}

.react-html5-camera-photo>.display-error {
    width: 100%;
}

.react-html5-camera-photo>.display-error h1 {
    display: none;
}

.react-html5-camera-photo>.display-error:before {
    content: 'Camera blocked: Enable the camera on browser to continue';
    color: #FF0000;
    display: inline-block;
}
